import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import { DisplayGrid, ProductPromoCardSection } from './styles'

interface ProductPromoCardProps {
  imagePosition: 'Left' | 'Right'
  ctaText?: string
  ctaUrl?: string
  promotedProduct: {
    id: string
    headline: string
    short_description: string
    feature_image: any
  }
}

const ProductPromoCard = (props: ProductPromoCardProps) => {
  const { imagePosition, ctaText, ctaUrl, promotedProduct } = props
  const image = getImage(promotedProduct?.[0]?.feature_image?.imgixImage)

  return (
    <ProductPromoCardSection>
      <Wrapper>
        <DisplayGrid className={`image${imagePosition}`}>
          <div className="imageBlock">
            {image ? (
              <GatsbyImage
                alt={promotedProduct?.[0]?.feature_image?.description || ''}
                image={image}
              />
            ) : null}
          </div>
          <div className="contentBlock">
            <Typography className="subhead" component="h2" variant="h2">
              {promotedProduct?.[0]?.headline}
            </Typography>
            <Typography className="body" component="p" variant="body1">
              {promotedProduct?.[0]?.short_description}
            </Typography>
            <LinkCTA arrow directUrl={ctaUrl}>
              {ctaText}
            </LinkCTA>
          </div>
        </DisplayGrid>
      </Wrapper>
    </ProductPromoCardSection>
  )
}

export default ProductPromoCard

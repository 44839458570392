import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ProductPromoCardSection = styled.section`
  background-color: #ffffff;
  padding: 97px 0;

  .subhead {
    color: ${rspTheme.palette.secondary.dark};
    margin-bottom: 24px;
    max-width: 32ch;
  }

  .body {
    margin-bottom: 24px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .subhead {
      font-size: 2.8rem;
    }
  }
`

export const DisplayGrid = styled.div`
  display: grid;
  grid-column-gap: 80px;
  grid-row-gap: 32px;

  .gatsby-image-wrapper {
    max-width: 645px;
    border-radius: 8px;
  }

  &.imageLeft {
    grid-template-columns: 3fr 2fr;
    .imageBlock {
      order: 1;
    }
    .contentBlock {
      order: 2;
    }
  }
  &.imageRight {
    grid-template-columns: 2fr 3fr;
    .imageBlock {
      order: 2;
    }
    .contentBlock {
      order: 1;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    &.imageLeft,
    &.imageRight {
      grid-template-columns: 1fr;

      .imageBlock {
        order: 1;
      }
    }
  }
`

import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import CarouselHero from '../components/CarouselHero'
import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import ThreeIcons from '../components/Homepage/ThreeIcons'
import ImpactModule from '../components/Impact'
import KeyAreas from '../components/KeyAreas'
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import ProductPromoCard from '../components/ProductPromoCard'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StepForm from '../components/StepForm'
import TrainstopSection from '../components/TrainstopSection'
import Testimonial from '../components/UI/Testimonial'
import createDownloadObject from '../utils/createDownloadObject'

const FlexiblePage = (props: PageProps<Queries.FlexiblePageQuery>) => {
  const pageData = props?.data?.contentstackPageFlexible
  const allProductPages = props.data.productPromoPages.nodes
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  let resourcesByFilter
  switch (props.pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...props?.data?.resourceCardsByResourceTypeFilter?.nodes,
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [...props?.data?.resourceCardsByIndustryFilter?.nodes]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...props?.data?.resourceCardsByRoleFilter?.nodes]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards =
    props?.data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(props, 'props')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      {pageData?.page_header?.[0]?.internal?.type ===
        'Contentstack_section_hero_banner' && (
        <Hero content={pageData?.page_header?.[0]} />
      )}
      {pageData?.page_header?.[0]?.internal?.type ===
        'Contentstack_section_hero_carousel' && (
        <CarouselHero
          headline={pageData?.page_header?.[0]?.headline}
          locale={pageData?.locale}
          maxSlides={pageData?.page_header?.[0]?.max_slides}
          type={pageData?.page_header?.[0]?.type}
        />
      )}
      {pageData?.introduction?.[0]?.internal?.type ===
        'Contentstack_section_feature_overview' && (
        <FeatureOverview
          body={
            pageData?.introduction?.[0]?.overview_description ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_description
          }
          headline={
            pageData?.introduction?.[0]?.headline ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_headline
          }
        />
      )}
      {pageData?.introduction?.[0]?.internal?.type ===
        'Contenstack_section_overview_icon_graphics' && (
        <ThreeIcons content={pageData?.introduction?.[0]} />
      )}

      {pageData?.optional_sections?.map((section: any) => (
        <React.Fragment key={section.id}>
          {section?.internal?.type === 'Contentstack_section_train_stop' && (
            <TrainstopSection
              ctaText={section?.cta_text}
              headline={section?.headline}
              isHeroTrainStop={section?.is_hero_train_stop}
              slides={section?.slides}
            />
          )}
          {section?.internal?.type === 'Contentstack_section_page_body' && (
            <PageBody
              content={section}
              downloadResource={downloadResourceObject}
              noBottomPadding
            />
          )}
          {section?.internal?.type === 'Contentstack_section_impact_stats' && (
            <ImpactModule content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_key_areas' && (
            <KeyAreas content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_lead_gen_form' &&
            section?.variant === 'Step' && <StepForm formData={section} />}
          {section?.internal?.type ===
            'Contentstack_section_feature_overview' && (
            <FeatureOverview
              body={
                section?.overview_description ||
                section?.topic?.[0]?.overview_description
              }
              headline={
                section?.headline || section?.topic?.[0]?.overview_headline
              }
            />
          )}
          {section?.internal?.type === 'Contentstack_section_testimonial' && (
            <Testimonial
              citation={section?.testimonial?.[0]?.source}
              testimonial={section?.testimonial?.[0]?.testimonial}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_product_promo_card' && (
            <ProductPromoCard
              ctaText={section.cta_text}
              ctaUrl={
                allProductPages?.filter(
                  (page: any) =>
                    page.product?.[0]?.id === section.promoted_product?.[0]?.id,
                )?.[0]?.url
              }
              imagePosition={section.image_position}
              promotedProduct={section.promoted_product}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_set' && (
            <RelatedResources
              content={{
                related_resources: resourcesByFilter || [],
                ...section,
              }}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_solutions_card_carousel' && (
            <RelatedSolutions relatedSolutions={section} />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.FlexiblePageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageFlexible?.schema_markup} />
    <SchemaMarkupBreadcrumb pageTitle={data?.contentstackPageFlexible?.title} />
  </>
)

export default FlexiblePage

export const data = graphql`
  query FlexiblePage(
    $pageId: String
    $filterId: String
    $locale: String
    $downloadResourceId: String
  ) {
    contentstackPageFlexible(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ... on Contentstack_section_hero_banner {
          ...PageHeaderSection
        }
        ... on Contentstack_section_hero_carousel {
          headline
          max_slides
          type
          internal {
            type
          }
        }
      }
      introduction {
        ... on Contentstack_section_feature_overview {
          ...FeatureOverviewSection
        }
        ... on Contentstack_section_overview_icon_graphics {
          ...ThreeIconOverviewSection
        }
      }
      optional_sections {
        ... on Contentstack_section_page_body {
          ...PageBodySection
        }
        ... on Contentstack_section_impact_stats {
          ...ImpactStatsSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
        ... on Contentstack_section_lead_gen_form {
          ...LeadGenForm
        }
        ... on Contentstack_section_key_areas {
          ...KeyAreasSection
        }
        ... on Contentstack_section_testimonial {
          ...TestimonialSection
        }
        ... on Contentstack_section_product_promo_card {
          ...ProductPromoCard
        }
        ... on Contentstack_section_feature_overview {
          ...FeatureOverviewSection
        }
        ... on Contentstack_section_resource_card_set {
          ...RelatedResourcesSection
        }
        ... on Contentstack_section_solutions_card_carousel {
          ...FeaturedProgramsSection
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    productPromoPages: allContentstackPageProduct(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        url
        product {
          id
        }
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
